<template>
  <v-card flat>
    <v-container>
    
         <v-row justify="center">
          <p class="text-h5"> {{$t('Tagreader settings')}}</p>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              dense
              v-model.number="station.cut_tag"
              :label="$t('Cut digits position')"
            >
            </v-text-field>
          </v-col>
        </v-row >
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-select
              outlined
              dense
              :label="$t('Hex/Dec digits')"
              v-model="station.tag_to_dec"
              :items="base"
              item-value="id"
              item-text="name"
            ></v-select>
          </v-col>
        </v-row >
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-switch
              v-model="station.has_shift"
              :label="$t('full_lenght')"
              color="#196619"
              :true-value="1"
              :false-value="0"
              hide-details
            ></v-switch>
          </v-col>
        </v-row >
        <v-row justify="center">
          <v-col cols="12" md="4" align="right">
            <v-btn
              color="#196619"
              dark
              min-width="150"
              :loading="loading"
              @click="save"
            >{{ $t("save") }}</v-btn>
          </v-col>
        </v-row>
     
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Tagreader",
  data() {
    return {
      ws: false,
      loading: false,
      station: null,
      base: [
        {id: 0, name: this.$t('Hexadecimal') },
        {id: 1, name: this.$t('Decimal') },
      ]
    };
  },
  methods: {
    async initialize () {
        let res = null
        try { 
          res = await this.$http.get("/system")
          this.station = res.data
        } catch (e) {
          console.log(e)
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        } 
    },
    proceedAction(act) {
      this.action = act;
      this.dialog = true;
    },
    async save() {
      let res = null;
      try {
        this.loading = true;
        res = await this.$http.post(`/system`, this.station);
        this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
      } catch (e) {
        console.log(e);
        this.$emit("alertMsg", {
          type: "error",
          text: e.message + "\n" + res?.error,
        });
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
  created () {
      this.initialize()
    },
};
</script>

